// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

type givenGift = {
  currency?: string
  prodNum: number
  prodId: string
  deviceId: string
  dbtId: string
  price?: number
}

// 查询道具赠送列表
export const queryGivenList = (data: { pageSize: number; pageNum: number; deviceId?: string; dbtId: string }) =>
  request('/payAdminServer/given_gift/getGivenGiftList', null, 'POST_JSON', data)

// 查询内购id列表
export const queryProductListByDbtId = (data: { dbtId: string|number }) =>
  request('/payAdminServer/product/getProductListByDbtId', null, 'POST_JSON', data)

// 新增道具赠送
export const addGivenGift = (data: givenGift) =>
  request('/payAdminServer/given_gift/addGivenGift', null, 'POST_JSON', data)

// 重试道具赠送
export const retryGivenGift = (data: givenGift) =>
  request('/payAdminServer/given_gift/retryGivenGift', null, 'POST_JSON', data)
